import React from "react"
import { graphql } from "gatsby"
import Layout from "@yumgmbh/gatsby-theme-yum-components/src/components/modules/Layout"
import SEO from "@yumgmbh/gatsby-theme-yum-components/src/components/modules/Seo"
import BodyBlocks from "@yumgmbh/gatsby-theme-yum-components/src/components/technical/BodyBlocks"
import KoinnoBannerBlock from "../@yumgmbh/gatsby-theme-yum-components/components/blocks/KoinnoBannerBlock"
import HeroRotationBlock from "../components/blocks/HeroRotationBlock"
import { useTranslation } from "react-i18next"

const IndexPage = ({ data }) => {
  const { story } = data
  const { i18n } = useTranslation()
  const language = story?.fields.language

  language !== i18n.language && i18n.changeLanguage(language)

  const content = JSON.parse(story.content)
  const name = story.name

  return (
    <Layout language={language}>
      <SEO content={content} name={name} />
      <HeroRotationBlock
        block={{
          _uid: "custom-1234",
          hero_slides: content.hero_rotation,
        }}
      />
      <BodyBlocks body={content.body} />
      <KoinnoBannerBlock />
    </Layout>
  )
}

export const data = graphql`
  {
    story: storyblokEntry(full_slug: { eq: "en/" }) {
      name
      content
      full_slug
      fields {
        language
      }
    }
  }
`

export default IndexPage
